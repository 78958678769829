import consumer from "./consumer";

//consumer.subscriptions.create("ChatChannel", {
const chatChannel = consumer.subscriptions.create("ChatChannel", {
  connected() {},
  disconnected() {},
  received(data) {
    if (data["text"] != null) {
      var badgeCount = data["unread_chats_count"];
      var content = data["text"];
    }
    /* else if (data["image"] != null) {
      var badgeCount = 0; //data.unread_chats_count
      badgeCount++;
      var content = `
        <a href="#" onclick="location.reload()">
          <i class="fas fa-image fa-3x"></i>
          <br>
          <b>Open New Image</b>
        </a>
      `;
    }
    */
    if (data["isCurrent_user"] == true) {
      var chat_text = `
      <div class="chat">
        <div class="message" data-user-id="${data["user_id"]}">
          <div class="chat-body">
            ${
              isUrl(content)
                ? `<a href="${content}" target="_blank">${content}</a>`
                : content
            }
          </div>
        </div>
        <div class="time"  data-user-id="${data["user_id"]}">
          ${data["time_current"]}
        </div>
      </div>
      `;
      //console.log(badgeCount)
    } else {
      var chat_text = `
      <div class="chat">
        <div class="message" data-user-id="${data["user_id"]}">
          <div class="chat-body">
            ${
              isUrl(content)
                ? `<a href="${content}" target="_blank">${content}</a>`
                : content
            }
          </div>
        </div>
        <div class="time"  data-user-id="${data["user_id"]}">
          ${data["time_current"]}
        </div>
      </div>
      `;
      navigator.setAppBadge(badgeCount);
    }
    $("#chats").append(chat_text);

    const room = document.getElementById(data["room_uuid"]);
    if (data["text"]) {
      var content = data["text"];
    } else {
      var content = "New Image";
    }
    const newContent = `
      <div class="chat_content">
        <p>
          <span>${content}</span>
        </p>
        <div class="chat_created_at">
          ${data.time_current}
        </div>
      </div>
      <div class="dot_badge in_room_index"></div>
    `;

    if (room) {
      const oldContent = room.querySelector(".chat_content");
      if (oldContent) {
        oldContent.innerHTML = newContent;
      } else {
        console.error("oldContentが見つかりません: .chat_content");
      }
    } else {
      const existingElement = document.querySelector(
        `.friend[data-room-id="${data["room_uuid"]}"]`
      );
      if (existingElement) {
        const chatContent = existingElement.querySelector(".chat_content");
        chatContent.innerHTML = newContent;
      } else {
        // Create new content
        const newContentHtml = `
          <a class="open_slide_modal_right" href="/rooms/${data["room_uuid"]}" data-url="/rooms/${data["room_uuid"]}">
            <div class="friend Editlink" data-room-id="${data["room_uuid"]}">
              <img alt="A position of worker now" width="50" class="friend_img circle" ssl_detected="false" src="/assets/saskas/no_image.svg">
              <p>
                <strong>No name?</strong>
              </p>
              <div id="${data["room_uuid"]}">
                <div class="chat_content">
                  <p>
                    <span>${content}</span>
                  </p>
                  <div class="chat_created_at">
                    ${data.time_current}
                  </div>
                </div>
                <div class="dot_badge in_room_index"></div>
              </div>
            </div>
          </a>
        `;
        $(".friendslist").prepend(newContentHtml);
      }
    }

    var partner_uuid = document.getElementById(data.partner_uuid);
    // Check if .dot_badge already exists
    if (!partner_uuid.querySelector(".dot_badge")) {
      const dotBadgeElement = document.createElement("span");
      dotBadgeElement.className = "dot_badge in_content4";
      partner_uuid.appendChild(dotBadgeElement);
    }
    //$("#chats").append(chat_image);
    var element = document.getElementById("scrollToBottom");
    element.scrollIntoView({ behavior: "smooth" });
    //document.getElementById('chats').insertAdjacentHTML('afterend', chat_text);
  },
  speak: function (data) {
    const current_user_id = $("#current_user_id").val();
    const partner_id = $("#partner_id").val();
    const partner_uuid = $("#partner_uuid").val();
    const text = $("#text").val();
    const room_id = $("#room_id").val();
    const room_uuid = $("#room_uuid").val();
    const image = $("image").val();
    const time_current = $("#time_current").val();
    return this.perform("speak", {
      text: text,
      current_user_id: current_user_id,
      partner_id: partner_id,
      partner_uuid: partner_uuid,
      room_id: room_id,
      room_uuid: room_uuid,
      image: image,
      time_current: time_current,
    });
  },
});

function isUrl(str) {
  // URLの形式に合致するかチェックする正規表現
  const regex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  return regex.test(str);
}
/*
//エンターキーで送信*フォームがtext_fieldで有効（text_areaでは改行するため）
$(document).on('keypress', '[data-behavior~=room_speaker]', function(event) {
  if (event.keyCode === 13) {
    chatChannel.speak(event.target.value);
    event.target.value = '';
    return event.preventDefault();
  }
});
*/
//送信ボタンで送信

document.addEventListener("DOMContentLoaded", function () {
  var text = document.getElementById("text");
  var button = document.getElementById("chat_register_button");
  button.addEventListener("click", function () {
    var content = text;
    chatChannel.speak(content);
    text.value = "";
  });
});

window.chatChannel = chatChannel;
