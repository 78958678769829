import consumer from "./consumer"

consumer.subscriptions.create("VolunteerChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    document.getElementById(data.request_uuid).classList.add("disable_btn");

    if (data.request) {
      const newContent = `
        <div class="main_package_buttons" id="${data.request.uuid}">
          <a class="open_slide_modal_right btn btn-border-shadow btn-border-shadow--color3" href="/requests/${data.request.uuid}" data-url="/requests/${data.request.uuid}" data-latitude="${data.request.latitude}" data-longitude="${data.request.longitude}">
            <div class="small_font">
              <i class="fas fa-solid fa-bell"></i>
              Check this out
            </div>
            Yore request grabed!
          </a>
        </div>
      `;
      $("#new_requests_content").append(newContent);
      const addedElement = $(
        "#new_requests_content .main_package_buttons"
      ).last();
      addedElement.css("animation", "slideInRight 1s ease-out");

    }
  }
});