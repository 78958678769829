import consumer from "./consumer";

// 「const chatChannel =」を追記
const chatChannel = consumer.subscriptions.create("RoomChannel", {
  /*
  received(data) {
    var room_text = `
        <div class="friend">
          <div class="chat_created_at">
            ${data.created_at}
          </div>
          <i class="friend_img fas fa-user-circle fa-3x"></i>
          <p>
            <strong>
                No name
            </strong>
            ${data.text}
          </p>
          <div class="dot_badge"></div>
        </div>
      `;
    $(".friends").append(room_text);
    //var element = document.getElementById("scrollToTop");
   // element.scrollIntoView({ behavior: "smooth" });
  },

  // 仮引数 function(message)のmessage
  // 実引数 event.target.value
  // room_channel.rbのspeakアクションを動かすために、speak関数を定義
  speak(data) {
    //return this.perform('speak', {message: message});
    const current_user_id = 2//$("#current_user_id").val();
    const partner_id = 125//$("#partner_id").val();
    const text = "kokoko"//$("#chat_text").val();
    const room_id = $("#room_id").val();
    const image = $("#chat_image").val();
    const time_current = $("#time_current").val();
    return this.perform("speak", {
      text: text,
      current_user_id: current_user_id,
      partner_id: partner_id,
      room_id: room_id,
      image: image,
      time_current: time_current

  })
}
*/
});
