// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
import Rails from "@rails/ujs";
//= require cableRails.start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
//require("bootstrap") //Bootstrapを追加
//import '../stylesheets/application';
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//タイムゾーン取得（取得した時差「tz」はapplication/controller内で@tzとして扱う）
jQuery(function () {
  $.cookie("tz", new Date().getTimezoneOffset());
});
