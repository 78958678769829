// app/javascript/channels/request_channel.js
import consumer from "./consumer";

consumer.subscriptions.create("RequestChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.request) {
      const newContent = `
        <div class="main_package_buttons" id="${data.request.uuid}">
          <a class="open_slide_modal_right btn btn-border-shadow btn-border-shadow--color3" href="/requests/${data.request.uuid}" data-url="/requests/${data.request.uuid}" data-latitude="${data.request.latitude}" data-longitude="${data.request.longitude}">
            <div class="small_font">
              <i class="fas fa-solid fa-bell"></i>
              Check this out
            </div>
            A new job just popped up!
          </a>
        </div>
      `;
      $("#new_requests_content").append(newContent);
      const addedElement = $(
        "#new_requests_content .main_package_buttons"
      ).last();
      addedElement.css("animation", "slideInRight 1s ease-out");

      // クリックイベントを設定して、クリックされたリンクタグを消す
      addedElement.find("a").on("click", function (event) {
        $(this).parent().remove();
      });

      // フッターのmapアイコンにドットバッジを付与
      const footer = document.querySelector(".saska_footer .content2");
      if (footer && !footer.querySelector(".dot_badge")) {
        const dotBadgeElement = document.createElement("div");
        dotBadgeElement.className = "dot_badge in_content4";
        footer.appendChild(dotBadgeElement);
      }

      // footerボタンをクリックしたときにdot_badgeを非同期で削除
      document.querySelector(".footer_button.content2").addEventListener("click", function () {
        const footer = document.querySelector(".saska_footer .content2");
        const dotBadge = footer.querySelector(".dot_badge");
        if (dotBadge) {
          dotBadge.remove();
        }
      });

      // Google MapsにInfoWindowを追加する処理
      if (typeof google !== "undefined" && typeof google.maps !== "undefined") {
        const latLng = {
          lat: parseFloat(data.request.latitude),
          lng: parseFloat(data.request.longitude),
        };
        var requestContent = `
          <a href="/requests/${
            data.request.uuid
          }" class="open_slide_modal_right Editlink price_tag" data-url="/requests/${
          data.request.uuid
        }" data-latitude="${data.request.latitude}" data-longitude="${
          data.request.longitude
        }">
            <div style="padding:3px 5px 0 5px; text-align:center;">
              <strong>
                <i class="fas fa-running map_info_icon green_back white_font"></i>
                Errand runner wanted
                <br>
                ${data.request.currency}
                ${data.request.reward}
                →
                ${data.request.deadline}
                <br>
                ${
                  data.request.radius
                    ? `<i class="fas fa-camera"></i> ${data.request.items_count}/${data.request.item_number}`
                    : ""
                }
              </strong>
            </div>
          </a>
        `;

        var infowindow = new google.maps.InfoWindow({
          content: requestContent,
          position: latLng,
          disableAutoPan: true,
        });
        infowindow.open(window.map);

        // マーカーとInfoWindowの管理
        window.markers = window.markers || {};
        window.markers[data.request.uuid] = infowindow;
      } else {
        // 地図が存在しない場合の初期化（初回ロード時）
        window.initMap = function () {
          const map = new google.maps.Map(
            document.getElementById("request_map"),
            {
              center: {
                lat: parseFloat(data.request.latitude),
                lng: parseFloat(data.request.longitude),
              },
              zoom: 10,
            }
          );
          const latLng = {
            lat: parseFloat(data.request.latitude),
            lng: parseFloat(data.request.longitude),
          };
          var requestContent = `
            <a href="/requests/${
              data.request.uuid
            }" class="open_slide_modal_right Editlink price_tag" data-url="/requests/${
            data.request.uuid
          }" data-latitude="${data.request.latitude}" data-longitude="${
            data.request.longitude
          }">
              <div style="padding:3px 5px 0 5px; text-align:center;">
                <strong>
                  <i class="fas fa-running map_info_icon green_back white_font"></i>
                  Errand runner wanted
                  <br>
                  ${data.request.currency}
                  ${data.request.reward}
                  →
                  ${data.request.deadline}
                  <br>
                  ${
                    data.request.radius
                      ? `<i class="fas fa-camera"></i> ${data.request.items_count}/${data.request.item_number}`
                      : ""
                  }
                </strong>
              </div>
            </a>
          `;

          var infowindow = new google.maps.InfoWindow({
            content: requestContent,
            position: latLng,
            disableAutoPan: true,
          });
          infowindow.open(map);
          window.map = map; // グローバル変数にマップを保存

          // マーカーとInfoWindowの管理
          window.markers = window.markers || {};
          window.markers[data.request.uuid] = infowindow;
        };
        // initMapを即座に呼び出して初期化
        window.initMap();
      }
    }
  },
});
